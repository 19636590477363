/*@import "/node_modules/@material/textfield/mdc-text-field.scss";*/

a.link{
    color: #312783;
}

.bg-main {
    background: ghostwhite;
    padding: 0 !important;
}
.header-nav-container{
    margin:auto;
    height: 6vh;
    background-size: cover;
    border-bottom: 1px solid #312783;
}
.main-container{
    padding-right: 0!important;
    padding-left: 0!important;
    min-height: 88vh;
    overflow-x: hidden;
}

.main-frame{
    width: 70%;
    margin: auto;
    background: white;
}
.front-page-container{
    margin: 25vh auto 20% auto;
    width: 50%;
    text-align: center;
}

.topSpace{
    margin-top:1rem;
}

body{
    font-family: Roboto, sans-serif;
}
/*Font*/
.caption-label{
    color: rgba(0, 0, 0, 0.54);
    font-size: 0.75rem;
    font-weight: 400;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1.375em;
}
.data-field {
    margin-bottom: 10px;
}
.data-field:after {
    content: '.';
    visibility: hidden;
    margin-bottom: 5px;
}

.attention{
    font-size: 1.2rem;
    font-weight: bold;
    color: #F00;
}

/*Navigation*/
.mdc-drawer{
    z-index: 100!important;
}
.mdc-drawer-scrim{
    z-index: 99!important;
}
.drawer-navigation{
    padding-top: 6vh;
    text-align: center;
    overflow-y: auto;
}
.drawer-navigation a:hover {
    text-decoration: none;
}

.mdc-drawer{
    box-shadow:none!important;
    border-color:#312783!important;
}


.nav-section{
    color: #fff;
    background: #312783;
    line-height: 18pt;
    font-size: 10pt;
}
.nav-link {
    border-bottom: 1px lightgray solid;
    transition: 0.3s;
    color:black;
    text-decoration: none;
}
.nav-link:hover{
    background: rgba(172, 202, 14, 1);
    text-decoration: none;
}
button.icon-button-styler{
    background: none;
    border: none;
    font-size: 40px;
    padding: 0.85vh 0.45vh 0.45vh 0.45vh;
    color:#312783;
}

button.icon-button-styler:hover{
    background: rgba(172, 202, 14, 1);
}

.align-right{
    text-align: right;
}
.align-left{
    text-align: left;
}
.align-center{
    text-align: center;
}
.nav-headline{
    font-size: 3vh;
    line-height: 4.5vh;
    color: #312783;
    margin-top: 0.8vh;
}

.logout-button{
    margin-bottom: 50px;
}
/*image styles*/
img.user-image{
    max-width:100%;
    height: 100%;
    object-fit: cover;
}
.image-container{
    width:18rem;
    height: 12rem;
    margin-bottom: 10px;
}

img.logo-image{
    position: static;
    height: 2.5vh;
    margin-left: 10px;
    margin-top: -5px;
}
img.card-back{
    width: 100%;
}
img.front-image{
    width: 80%;
    padding:15px;
}
/*file-upload*/

input[type="file"] {
    display: none;
}
.file-upload-button {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    margin-right: 2px;
    width: 9rem;
    text-align: center;
}
/*Re-Style table*/

.login-card{
    margin: auto;
    max-width: 30rem;
    top: 2rem;
}

.register-card{
    margin: auto;
    max-width: 40rem;
    top: 2rem;
}

.card-header, .card-footer, .modal-header{
    background-color: rgba(172,202,14,1)!important;
    border-top:1px solid #312783!important;
    border-bottom:1px solid #312783!important;    
}

.modal-dialog{
    max-width: 800px!important;
}

.small-modal{
    max-width: 360px!important;
}

.padding-top-15{
    padding-top: 15px;
}

a.padding-left-15{
    padding-left: 15px;
}
.padding-right-15{
    padding-right: 15px;
}

.chip-margin{
    margin:2px;
}

/*Headline settings*/
h1, h2{
    padding: 5px 0 5px 0;
    font-size: 1.5rem!important;
    line-height: 1!important;
    margin-bottom: 0!important;
}

/*Footer*/

.footer-container{
    padding-top: 1.5vh;
    height: 6vh;
    color: #312783;
    border-top: 1px solid #312783;
}

a.footer-link{
    padding-left: 25px;
    font-size: 14pt;
    color: #312783;
}

/*Forms*/
.padding-15{
    padding:15px;
}

.padding-bottom-15{
    padding-bottom: 15px;
}

/*Manual Style*/

iframe.manual{
    width: 100%;
    height: 75vh;
    border: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.close-button{
    position: absolute;
    right: 15px;
    top: 15px;

}

/* Access Restriction Style*/

iframe.access-restricted-frame,
iframe.home-frame{
    width: 100%;
    height: 99%;
    border: none;
}

.alert-menu-link{
     background-color: #FF9999;
 }

.alert-menu-link:hover{
    background-color: #FF3333;
}


/*Media Calls*/

@media (max-width: 991px) {

}

@media (max-width: 767px) {
    .main-frame{
        width: 100%;
    }
    .login-card{
        top: 0;
    }
    .register-card{
        top: 0;
    }
}

